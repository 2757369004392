<template>
  <div class="dg-ppm-details-container">
    <div class="dg-ppm-details-panel">
      <div class="dg-ppm-details-panel__main">
        <PPMBackBtn />
        <div class="dg-ppm-details__shimmer-wrapper" v-if="fetchingPPMDetails">
          <div class="shim-container">
            <div class="shine lines"></div>
          </div>
          <div class="shine box"></div>
          <div class="shim-container">
            <div class="shine lines"></div>
            <div class="shine lines"></div>
          </div>
        </div>
        <div class="dg-ppm-details-panel__item" v-else>
          <PPMDetailsHeader />
          <PPMDetailsWorkflowStatus />
          <PPMDetailsMainInfo />
          <PPMContractorTree v-release="'subcontractor_tree'" />
        </div>
      </div>
      <transition name="slider-move" mode="out-in">
        <div class="dg-ppm-details-panel__item dg-ppm-details-panel__aside" v-if="selectedContract">
          <PPMSubContractorSlider />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PPMBackBtn from "../components/PPMBackBtn.vue";
import PPMDetailsHeader from "../components/PPMDetails/PPMDetailsHeader.vue";
import PPMDetailsWorkflowStatus from "../components/PPMDetails/PPMDetailsWorkflowStatus.vue";
import PPMDetailsMainInfo from "../components/PPMDetails/PPMDetailsMainInfo.vue";
import PPMContractorTree from "../components/PPMDetails/PPMContractorTree.vue";
import PPMSubContractorSlider from "../components/PPMDetails/PPMSubContractorSlider.vue";

export default {
  components: {
    PPMBackBtn,
    PPMDetailsHeader,
    PPMDetailsWorkflowStatus,
    PPMDetailsMainInfo,
    PPMContractorTree,
    PPMSubContractorSlider,
  },
  computed: {
    ...mapGetters(["fetchingPPMDetails", "selectedContract"]),
  },
  created() {
    this.$store.dispatch("fetchPPMDetails", { cid: this.$route.params.id, ppmId: this.$route.params.ppmId });
  },
};
</script>

<style lang="scss">
.dg-ppm-details {
  &-container {
    margin: 30px auto;
    width: 100%;
    max-width: 1400px;
  }

  &-panel {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 8px;

    &__item {
      margin-top: 12px;
      background-color: #ffffff;
      padding: 20px;
      box-shadow: 0px 12px 24px rgba(17, 42, 56, 0.1);
    }

    &__main {
      flex: 1;
      max-width: 1040px;
    }

    &__aside {
      margin-top: 37px;
      margin-left: 16px;
      width: 35%;
    }
  }

  &__shimmer-wrapper {
    margin-top: 12px;
    padding: 20px;
    background: #fff;
    margin-bottom: 20px;

    .shine {
      background: #f6f7f8;
      background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
      background-repeat: no-repeat;
      background-size: 800px 300px;
      display: inline-block;
      position: relative;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeholderShimmer;
      animation-timing-function: linear;
    }

    .box {
      height: 200px;
      width: 100%;
      margin-top: 15px;
    }

    .shim-container {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      vertical-align: top;
    }

    .lines {
      width: 50%;
      height: 20px;
      margin-top: 10px;
    }
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.slider-move-enter-active,
.slider-move-leave-active {
  transition: all 0.3s ease;
}
.slider-move-enter,
.slider-move-leave-to {
  transform: translateX(30px);
  opacity: 0;
}
</style>
