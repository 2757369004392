<template>
  <div class="ppm-relation-files">
    <h4 class="ppm-relation-files__title">{{ $t("ppm.details.contract_files.label") }}</h4>
    <div class="ppm-relation-files__list">
      <PPMDetailsFile
        v-for="docType in documentTypes"
        :key="docType"
        :doc-type="docType"
        :contract-details="contractDetails"
        @on-files-change="updateFilesInSubContract"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PPMDetailsFile from "../PPMDetailsFile.vue";

export default {
  components: {
    PPMDetailsFile,
  },
  computed: {
    documentTypes() {
      const listOfDocumentTypes = ["privacy_contract", "toms", "main_contract", "additional_attachments"];
      if (this.isWorkflowTypeReview) {
        listOfDocumentTypes.push("review_report");
      }
      return listOfDocumentTypes;
    },
    isWorkflowTypeReview() {
      return this.contractDetails.workflow_type.includes("review");
    },
    isSelectedContractIDEqualToPPMId() {
      return this.$route.params.ppmId === this.selectedPartner.id;
    },
    contractDetails() {
      // if current selected partner matches with the ppm details id
      // then use ppm details to render file component
      if (this.isSelectedContractIDEqualToPPMId) {
        return this.ppmDetails;
      }
      // if current selected partner is a subcontract
      // then change workflow-type and contract-type
      return {
        ...this.selectedPartner.contract_data,
        workflow_type: "import",
        contract_type: "dpa",
      };
    },
    selectedPartner() {
      return this.selectedContract.processor;
    },
    ...mapGetters(["ppmDetails", "selectedContract"]),
  },
  methods: {
    updateFilesInSubContract(files) {
      if (this.isSelectedContractIDEqualToPPMId) {
        this.$store.dispatch("updatePPMFiles", files);
      } else {
        this.$store.commit("UPDATE_SUBCONTRACTOR_TREE", {
          nodeId: this.selectedPartner.id,
          contractData: {
            ...this.selectedPartner.contract_data,
            files,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.ppm-relation-files {
  margin-top: 16px;

  &__title {
    color: #36b2e8;
    font-size: 14px;
  }

  &__list {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}
</style>
