<template>
  <dg-modal
    @submit="createSubcontract"
    @close="$emit('close')"
    :show="show"
    :options="{
      ...addSubcontractLabels,
      loading: this.creatingSubContract,
    }"
    size="lg"
  >
    <PPMForm
      mode="subcontract"
      :subcontractDetails="contractDetails"
      ref="addSubcontractForm"
      @on-create-success="addNewSubcontractDataToTree"
    />
  </dg-modal>
</template>

<script>
import { mapGetters } from "vuex";
import PPMForm from "../../PPMCreate/PPMForm.vue";

export default {
  components: {
    PPMForm,
  },
  data() {
    return {
      creatingSubContract: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    addSubcontractLabels() {
      return {
        title: this.$t("ppm.details.add_subcontract.title"),
        submitLabel: this.$t("ppm.details.add_subcontract.btn_labels.save"),
        closeLabel: this.$t("ppm.details.add_subcontract.btn_labels.close"),
      };
    },
    isSelectedContractIDEqualToPPMId() {
      return this.$route.params.ppmId === this.selectedPartner.id;
    },
    partnerData() {
      return this.ppmDetails.partnerDetails || {};
    },
    /**
     * Prepare subcontract data passed later to backend API
     */
    contractDetails() {
      // if current selected partner matches with the ppm details id
      if (this.isSelectedContractIDEqualToPPMId) {
        let subcontractData = {
          parent_id: this.ppmDetails.id,
          participant_id: this.partnerData.id,
        };
        if (this.ppmDetails.customer_role === "processor") {
          subcontractData["customer_role"] = "controller";
        }
        return subcontractData;
      }
      // if current selected partner is a subcontract
      return {
        parent_id: this.selectedPartner.id,
        participant_id: this.selectedPartnerInfo.id,
      };
    },
    selectedPartnerInfo() {
      return this.selectedContract.processor.partner_info;
    },
    selectedPartner() {
      return this.selectedContract.processor;
    },
    ...mapGetters(["ppmDetails", "selectedContractId", "selectedContract"]),
  },
  methods: {
    async createSubcontract() {
      this.creatingSubContract = true;
      // trigger `submitForm` method defined in PPMForm component
      await this.$refs.addSubcontractForm.submitForm();
      this.creatingSubContract = false;
    },
    addNewSubcontractDataToTree(newSubcontractData) {
      const { sub_contracts = [] } = this.selectedPartner;
      /**
       * As currently the backend sends name by appending both controller and processor names
       * So doing the same here
       * FIXME: Once backend is refactored to pass names separately, use processor name here
       */
      const newPartnerName = newSubcontractData.partners.map(partner => partner.name).join(", ");
      /**
       * After user adds subcontract, we need to update the tree data in the store
       */
      this.$store.commit("UPDATE_SUBCONTRACTOR_TREE", {
        nodeId: this.selectedContractId,
        subContractList: [
          ...sub_contracts,
          {
            ...newSubcontractData,
            partner_name: newPartnerName,
          },
        ],
        // pass refresh flag to re-render d3 tree
        refresh: true,
      });
      // close add subcontract modal
      this.$emit("close");
    },
  },
};
</script>
