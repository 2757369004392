import { mapGetters } from "vuex";

export default {
  computed: {
    isWorkflowTypeReview() {
      return this.ppmDetails.workflow_type === "review_contract";
    },
    isWorkflowTypeCreate() {
      return this.ppmDetails.workflow_type === "create_contract";
    },
    isWorkflowTypeImport() {
      return this.ppmDetails.workflow_type === "import_contract";
    },
    isContractTypeDPA() {
      return this.ppmDetails.contract_type === "dpa";
    },
    isContractTypeJCA() {
      return this.ppmDetails.contract_type === "jca";
    },
    isUserCustomer() {
      return this.userRole === "customer";
    },
    isUserDpo() {
      return this.userRole.includes("dpo");
    },
    userRole() {
      return this.userData?.role || "";
    },
    ...mapGetters(["ppmDetails", "companyData", "userData"]),
  },
};
