<template>
  <div class="dg-ppm-details-contract-status" @click="showStatusDropdown" data-testid="ppm-workflow-status-dropdown">
    <span
      :class="['dg-ppm-details-contract-status__text', contractStateClassname, { 'show-cursor': statusOptions.length }]"
    >
      {{ contractState }}
      <img src="../../images/version-down-white.svg" alt="" v-if="statusOptions.length" />
    </span>
    <ul
      class="dg-ppm-details-contract-status__dropdown"
      ref="statusDropdown"
      v-if="statusOptions.length && showStatusList"
    >
      <li
        class="dg-ppm-details-contract-status__item"
        v-for="status in statusOptions"
        :key="status.value"
        @click="onStatusChange(status.value)"
        :data-testid="`ppm-workflow-status-${status.value}`"
      >
        {{ status.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import { PPMDetailsMixin } from "../../mixins";

export default {
  mixins: [PPMDetailsMixin],
  data() {
    return {
      showStatusList: false,
    };
  },
  computed: {
    contractStateClassname() {
      if (this.isWorkflowTypeReview) {
        if (this.notCompliant) return "not-compliant";
        if (this.ppmDetails.review_report === "compliant_all") return "compliant";
        return this.ppmDetails.workflow_status;
      }
      if (this.isWorkflowTypeCreate) {
        return this.ppmDetails.workflow_status;
      }
      return "imported";
    },
    contractState() {
      if (this.isWorkflowTypeReview) {
        let review_status = this.ppmDetails.review_report;
        if (review_status === "unknown") {
          review_status = this.ppmDetails.workflow_status;
        }
        return this.$t(`ppm.details.workflow_status.review.${review_status}`);
      }
      if (this.isWorkflowTypeCreate) {
        return this.$t(`ppm.details.workflow_status.create.${this.ppmDetails.workflow_status}`);
      }
      return this.$t(`ppm.details.workflow_status.upload.import`);
    },
    notCompliant() {
      return this.ppmDetails.review_report.includes("not_compliant");
    },
    statusOptions() {
      let statusList = [];
      if (this.isUserCustomer) return statusList;
      if (this.isWorkflowTypeReview && this.isContractTypeJCA && this.hasReviewReportFiles) {
        statusList = [
          {
            name: this.$t("ppm.details.workflow_status.review.unknown"),
            value: "unknown",
          },
          {
            name: this.$t("ppm.details.workflow_status.review.compliant_all"),
            value: "compliant_all",
          },
          {
            name: this.$t("ppm.details.workflow_status.review.not_compliant_all"),
            value: "not_compliant_all",
          },
          {
            name: this.$t("ppm.details.workflow_status.review.not_compliant_legal"),
            value: "not_compliant_legal",
          },
          {
            name: this.$t("ppm.details.workflow_status.review.not_compliant_tom"),
            value: "not_compliant_tom",
          },
        ].filter(status => {
          return this.ppmDetails.review_report !== status.value;
        });
      }
      if (this.isWorkflowTypeCreate && this.hasPrivacyFiles) {
        statusList = [
          {
            name: this.$t("ppm.details.workflow_status.create.in_creation"),
            value: "in_creation",
          },
          {
            name: this.$t("ppm.details.workflow_status.create.created"),
            value: "created",
          },
        ].filter(status => {
          return this.ppmDetails.workflow_status !== status.value;
        });
      }
      return statusList;
    },
    partnerFiles() {
      return this.ppmDetails.files || [];
    },
    hasPrivacyFiles() {
      return this.partnerFiles.filter(file => file.file_type === "privacy_contract").length !== 0;
    },
    hasReviewReportFiles() {
      return this.partnerFiles.filter(file => file.file_type === "review_report").length !== 0;
    },
  },
  mounted() {
    window.addEventListener("click", this.closeDropdowns);
  },
  destroyed() {
    window.removeEventListener("click", this.closeDropdowns);
  },
  methods: {
    closeDropdowns(e) {
      if (this.$el && !this.$el.contains(e.target)) {
        this.showStatusList = false;
      }
    },
    showStatusDropdown() {
      this.showStatusList = !this.showStatusList;
    },
    async onStatusChange(newStatus) {
      // By default send chosen value as workflow status
      // this will work for workflow type other than review
      let formData = {
        workflow_status: newStatus,
      };
      // when workflow type is review, then chosen value is review_report
      if (this.isWorkflowTypeReview) {
        const reviewReport = newStatus;
        // By default workflow status is reviewed
        let workflowStatus = "";
        // if selected value is unknown, workflow status is in_review
        if (reviewReport === "unknown") {
          workflowStatus = "in_review";
        } else {
          // if selected value is other than unknown, workflow status is reviewed
          workflowStatus = "reviewed";
        }
        formData = {
          review_report: reviewReport,
          workflow_status: workflowStatus,
        };
      }
      try {
        const { id, ppmId } = this.$route.params;
        const endpoint = `/api1/v2/customers/${id}/privacy_contracts/${ppmId}`;
        await this.$http({
          url: endpoint,
          method: "PUT",
          data: formData,
        });
        this.$dgNotification.show({
          variant: "bold",
          type: "success",
          title: this.$t("ppm.details.workflow_status.successMsg"),
        });
        this.$store.dispatch("fetchPPMDetails", { cid: id, ppmId });
      } catch (error) {
        const { data = [] } = error;
        if (Array.isArray(data) && data.length) {
          this.$dgNotification.show({
            variant: "bold",
            type: "error",
            title: data[0].detail,
          });
        } else {
          this.$dgNotification.show({
            variant: "bold",
            type: "error",
            title: this.$t("ppm.details.workflow_status.errorMsg"),
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dg-ppm-details-contract-status {
  display: inline-block;
  margin-top: 12px;
  position: relative;

  &__text {
    display: inline-block;
    padding: 2px 8px;
    line-height: 20px;
    font-size: 14px;
    color: $white;
    user-select: none;

    &.show-cursor {
      cursor: pointer;
    }

    &.imported {
      background-color: #7e93a7;
    }

    &.compliant,
    &.created,
    &.reviewed {
      background-color: #5bc88d;
    }

    &.not-compliant {
      background-color: #c84e4b;
    }

    &.in_review {
      background-color: #fa9334;
    }

    &.in_creation {
      background-color: #bfccd2;
    }
  }

  &__dropdown {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 4px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #dfe4e9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 100%;
    transition: visibility 0s, opacity 0.5s linear;
    width: 270px;
    z-index: 1;
  }

  &__item {
    cursor: pointer;
    padding: 4px 8px;
    border-bottom: 1px solid #e9edef;

    &:hover {
      background-color: #f2f4f6;
    }
  }
}
</style>
