<template>
  <h1 class="dg-ppm-details-header" data-testid="ppm-details-header">
    {{ $t(`ppm.details.title.typeNames.${ppmDetails.contract_type}`) }}: {{ `${firstPartner} (${firstPartnerType})` }}
    <img
      class="dg-ppm-details-header__arrow-icon"
      src="../../images/one-way-arrow.svg"
      alt=""
      v-if="isContractTypeDPA"
    />
    <img class="dg-ppm-details-header__arrow-icon" src="../../images/two-way-arrow.svg" alt="" v-else />
    {{ `${secondPartner} (${secondPartnerType})` }}
  </h1>
</template>

<script>
import { PPMDetailsMixin } from "../../mixins";

export default {
  mixins: [PPMDetailsMixin],
  computed: {
    firstPartner() {
      if (this.isPartnerProcessor || this.isContractTypeJCA) {
        return this.companyData?.name;
      }
      return this.partnerData.name;
    },
    firstPartnerType() {
      if (this.isContractTypeDPA) {
        return this.$t("ppm.details.title.partnerTypes.controller");
      }
      return this.$t("ppm.details.title.partnerTypes.controller1");
    },
    secondPartner() {
      if (this.isPartnerProcessor || this.isContractTypeJCA) {
        return this.partnerData.name;
      }
      return this.companyData?.name;
    },
    secondPartnerType() {
      if (this.isContractTypeDPA) {
        return this.$t("ppm.details.title.partnerTypes.processor");
      }
      return this.$t("ppm.details.title.partnerTypes.controller2");
    },
    isPartnerProcessor() {
      return this.partnerData.role === "processor";
    },
    partnerData() {
      return this.ppmDetails.partnerDetails || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.dg-ppm-details-header {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  position: relative;
  margin: 0;

  &__arrow-icon {
    margin: 0 4px;
  }
}
</style>
