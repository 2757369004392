<template>
  <div class="dg-ppm-details-main-info__container">
    <div class="dg-ppm-details-main-info__main" data-testid="ppm-details-main-info">
      <div class="dg-ppm-details-main-info__item">
        <p class="dg-ppm-details-main-info__item__label">
          {{ $t("ppm.details.partner_company.label") }}
        </p>
        <p class="dg-ppm-details-main-info__item__content">{{ partnerName }}</p>
      </div>
      <div class="dg-ppm-details-main-info__item">
        <p class="dg-ppm-details-main-info__item__label">
          {{ $t("ppm.details.contract_type.label") }}
        </p>
        <p class="dg-ppm-details-main-info__item__content">
          {{ $t(`ppm.details.title.typeNames.${ppmDetails.contract_type}`) }}
        </p>
      </div>
      <div class="dg-ppm-details-main-info__item">
        <p class="dg-ppm-details-main-info__item__label">
          {{ $t("ppm.details.contact_person.label") }}
        </p>
        <p class="dg-ppm-details-main-info__item__content">{{ partnerContactFullName }}</p>
      </div>
      <div class="dg-ppm-details-main-info__item">
        <p class="dg-ppm-details-main-info__item__label">{{ $t("ppm.details.created.label") }}</p>
        <p class="dg-ppm-details-main-info__item__content">{{ dateFormatted(ppmDetails.created_at) }}</p>
      </div>
      <div class="dg-ppm-details-main-info__item">
        <p class="dg-ppm-details-main-info__item__label">
          {{ $t("ppm.details.status.label") }}
        </p>
        <p class="dg-ppm-details-main-info__item__content">
          <DgInput
            v-if="canChangeContractState"
            :attrs="{ value: ppmDetails.status }"
            input-type="select"
            :select-options="statusOptions"
            @change="changeStatus"
          />
          <template v-else>{{ $t(`ppm.details.status.options.${ppmDetails.status}`) }}</template>
        </p>
      </div>
    </div>
    <p class="dg-ppm-details-main-info__files-label">{{ $t("ppm.details.contract_files.label") }}</p>
    <div class="dg-ppm-details-main-info__files" v-if="documentTypes.length">
      <PPMDetailsFile
        v-for="docType in documentTypes"
        :key="docType"
        :doc-type="docType"
        :contract-details="ppmDetails"
        :data-testid="`${docType}_files`"
        @on-files-change="updateMainContractFiles"
      />
    </div>
  </div>
</template>

<script>
import { PPMDetailsMixin } from "../../mixins";

import PPMDetailsFile from "./PPMDetailsFile.vue";

export default {
  mixins: [PPMDetailsMixin],
  components: { PPMDetailsFile },
  computed: {
    partnerData() {
      return this.ppmDetails.partnerDetails || {};
    },
    partnerName() {
      return this.partnerData.name;
    },
    partnerContactData() {
      return this.ppmDetails.partnerContactPerson || {};
    },
    partnerContactFullName() {
      return `${this.partnerContactData.first_name} ${this.partnerContactData.last_name}`;
    },
    documentTypes() {
      const listOfDocumentTypes = ["privacy_contract", "toms", "main_contract", "additional_attachments"];
      if (this.isWorkflowTypeReview) {
        listOfDocumentTypes.push("review_report");
      }
      return listOfDocumentTypes;
    },
    canChangeContractState() {
      return this.isUserCustomer && this.$can("edit", "portal_for_dpas_and_jcas_change_contract_state");
    },
    statusOptions() {
      return [
        {
          name: this.$t("ppm.details.status.options.draft"),
          value: "draft",
        },
        {
          name: this.$t("ppm.details.status.options.terminated"),
          value: "terminated",
        },
        {
          name: this.$t("ppm.details.status.options.expired"),
          value: "expired",
        },
        {
          name: this.$t("ppm.details.status.options.in_force"),
          value: "in_force",
        },
      ];
    },
  },
  methods: {
    dateFormatted(dateStamp) {
      return formatDate(dateStamp, "MM/dd/yyyy");
    },
    async changeStatus(e) {
      try {
        const { id, ppmId } = this.$route.params;
        const endpoint = `/api1/v2/customers/${id}/privacy_contracts/${ppmId}`;
        await this.$http({
          url: endpoint,
          method: "PUT",
          data: {
            status: e.target.value,
          },
        });
        this.$dgNotification.show({
          variant: "bold",
          type: "success",
          title: this.$t("ppm.details.status.successMsg"),
        });
      } catch (error) {
        const { data = [] } = error;
        if (Array.isArray(data) && data.length) {
          this.$dgNotification.show({
            variant: "bold",
            type: "error",
            title: data[0].detail,
          });
        } else {
          this.$dgNotification.show({
            variant: "bold",
            type: "error",
            title: this.$t("ppm.details.status.errorMsg"),
          });
        }
      }
    },
    updateMainContractFiles(files) {
      this.$store.dispatch("updatePPMFiles", files);
    },
  },
};
</script>

<style lang="scss">
.dg-ppm-details-main-info {
  &__container {
    background: #f2f4f6;
    margin-top: 16px;
    padding: 20px;
  }

  &__main {
    display: flex;
  }

  &__files {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 12px;
  }

  &__item {
    flex: 1;
    &__label,
    &__content {
      margin: 0;
      font-size: 14px;
      line-height: 20px;

      & .dg-ds-input__input.select {
        background-color: #f2f4f6;
      }
    }

    &__label {
      color: #4d7080;
    }

    &__content {
      color: #003349;
      margin-top: 4px;
      font-weight: 600;
    }
  }

  &__files-label {
    margin: 0;
    color: #36b2e8;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 24px;
  }
}
</style>
