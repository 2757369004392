<template>
  <div class="ppm-descendants" v-if="subContractList.length">
    <h4 class="ppm-descendants__label">{{ $t("ppm.details.subcontractor_details.labels.subcontractors") }}</h4>
    <div class="ppm-descendants__section">
      <div class="ppm-descendants__parent">
        <h3 class="ppm-descendants__title">{{ selectedPartner.partner_name }}</h3>
      </div>
      <div class="ppm-descendants__list">
        <div class="ppm-descendants__item" v-for="child in subContractList" :key="child.id">
          <img src="../../../images/one-way-arrow.svg" />
          <h4 class="ppm-descendants__item__title">{{ child.partner_name }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    selectedPartner() {
      return this.selectedContract.processor;
    },
    subContractList() {
      return this.selectedPartner?.sub_contracts || [];
    },
    ...mapGetters(["selectedContract"]),
  },
};
</script>

<style lang="scss">
.ppm-descendants {
  margin-top: 16px;

  &__label {
    font-size: 14px;
    padding-bottom: 10px;
    border-bottom: 1px solid #cbd4dc;
  }

  &__title {
    font-size: 14px;
    background-color: #c7e5f2;
    padding: 4px 12px;
  }

  &__section,
  &__item {
    display: flex;
  }

  &__parent {
    flex: 1;
  }

  &__list {
    margin-left: 8px;
    width: 45%;
    flex: 1;
  }

  &__item {
    align-items: center;
    border-left: 1px solid #36b2e8;
    padding-left: 10px;

    &:not(:first-child) {
      margin-top: 10px;
    }

    &__title {
      margin: 0;
      margin-left: 10px;
      background-color: #dfe4e9;
      padding: 6px 12px;
    }

    & > img {
      width: 24px;
    }
  }
}
</style>
