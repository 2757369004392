<template>
  <div class="ppm-contract-relation">
    <PPMPartnerCard partner-type="controller" />
    <img class="ppm-contract-relation__arrow" v-if="isContractTypeJCA" src="../../../images/two-way-arrow.svg" />
    <img class="ppm-contract-relation__arrow" v-else src="../../../images/one-way-arrow.svg" />
    <PPMPartnerCard partner-type="processor" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PPMPartnerCard from "./PPMPartnerCard.vue";

export default {
  components: {
    PPMPartnerCard,
  },
  computed: {
    isContractTypeJCA() {
      return this.contractData.contract_type === "jca";
    },
    contractData() {
      return this.processorData.contract_data;
    },
    processorData() {
      return this.selectedContract.processor;
    },
    ...mapGetters(["selectedContract"]),
  },
};
</script>

<style lang="scss">
.ppm-contract-relation {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-top: 16px;

  &__arrow {
    width: 24px;
  }
}
</style>
