<template>
  <div class="dg-ppm-details-file" @mouseenter="showFilePopover">
    <div class="dg-ppm-details-file__container" ref="fileContainer">
      <img class="dg-ppm-details-file__icon" src="../../images/file-pin.svg" alt="" />
      <div class="dg-ppm-details-file__details">
        <p class="dg-ppm-details-file__info">
          {{ $t(`ppm.form.${docType}.label`) }}
        </p>
        <p class="dg-ppm-details-file__number">
          {{ $t("ppm.details.no_of_files", { totalFiles: listOfSortedFiles.length }) }}
        </p>
      </div>
      <img class="dg-ppm-details-file__down-arrow" src="../../images/version-down.svg" alt="" />
    </div>
    <div class="dg-ppm-details-file__dropdown" ref="filesDropdown">
      <div class="dg-ppm-details-file__dropdown__header">
        <h3 class="dg-ppm-details-file__dropdown__title">
          {{ versionTitle }}
        </h3>
        <dg-button
          v-if="allowManagingFiles && showAddFilesForAdditional && hasFilesPermission"
          class="dg-ppm-details-file__dropdown__add-new"
          variant="compact"
          variant-style="secondary"
          :loading="uploadingFiles"
          @click="openFileDialog"
        >
          + {{ addButtonLabel }}
        </dg-button>
        <input
          ref="fileInput"
          class="dg-ppm-details-file__dropdown__file-input"
          type="file"
          @change="uploadNewVersion"
        />
      </div>
      <ul class="dg-ppm-details-file__dropdown__list">
        <template v-if="listOfSortedFiles.length">
          <transition-group name="ppm-file-list">
            <li class="dg-ppm-details-file__dropdown__list-item" v-for="file in listOfSortedFiles" :key="file.id">
              <div class="dg-ppm-details-file__dropdown__version">
                {{ file | fileVersionWithDate(formatDate, isAdditionalDocument) }}
              </div>
              <div class="dg-ppm-details-file__dropdown__owner" :title="file.file_name">{{ file.file_name }}</div>
              <div class="dg-ppm-details-file__dropdown__controls">
                <button
                  @click="showDeleteFileModal(file)"
                  :title="$t('ppm.details.delete_btn_title')"
                  v-if="allowManagingFiles && hasFilesPermission"
                >
                  <img src="../../images/file-delete.svg" alt="" />
                </button>
                <a :href="getFixedUrl(file.url)" :download="file.file_name">
                  <img src="../../images/file-download.svg" alt="" />
                </a>
              </div>
            </li>
          </transition-group>
        </template>
        <template v-else>
          <li class="dg-ppm-details-file__dropdown__empty-list">
            {{ emptyFilesMessage }}
          </li>
        </template>
      </ul>
    </div>
    <DgModal
      size="sm"
      :show="showConfirmDeleteModal"
      :options="{
        title: $t('ppm.details.file_delete_popup.title'),
        description: $t('ppm.details.file_delete_popup.description'),
        closeLabel: $t('ppm.details.file_delete_popup.cancel'),
        submitLabel: $t('ppm.details.file_delete_popup.confirm'),
        loading: deletingFile,
      }"
      @close="closeDeleteModal"
      @submit="confirmDeleteFile"
    >
      <p>{{ $t("ppm.details.file_delete_popup.description") }}</p>
    </DgModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    docType: {
      type: String,
      required: true,
    },
    contractDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      uploadingFiles: false,
      showConfirmDeleteModal: false,
      fileForDeletion: null,
      deletingFile: false,
    };
  },
  filters: {
    fileVersionWithDate(file, formatDate, isAdditionalDocument) {
      if (isAdditionalDocument) {
        return `${formatDate(new Date(file.created_at), "dd.MM.yyyy HH:mm")}`;
      }
      return `V${file.version}: ${formatDate(new Date(file.created_at), "dd.MM.yyyy HH:mm")}`;
    },
  },
  computed: {
    contractId() {
      return this.contractDetails.id;
    },
    filteredFiles() {
      return this.contractDetails.files.filter(doc => doc.file_type === this.docType);
    },
    listOfSortedFiles() {
      return [...this.filteredFiles].sort((file1, file2) => new Date(file2.created_at) - new Date(file1.created_at));
    },
    versionTitle() {
      if (this.isAdditionalDocument) {
        return this.$t("ppm.details.additional_popover_title");
      }
      return this.$t("ppm.details.version_popover_title");
    },
    addButtonLabel() {
      if (this.isAdditionalDocument) {
        return this.$t("ppm.details.add_new_additional_document");
      }
      return this.$t("ppm.details.add_new_version");
    },
    emptyFilesMessage() {
      if (this.isAdditionalDocument) {
        return this.$t("ppm.details.empty_additional_files");
      }
      return this.$t("ppm.details.empty_files");
    },
    isReviewReport() {
      return this.docType === "review_report";
    },
    isAdditionalDocument() {
      return this.docType === "additional_attachments";
    },
    showAddFilesForAdditional() {
      // Hide add files button for additional document type and 5 files are uploaded
      if (this.isAdditionalDocument && this.listOfSortedFiles.length >= 5) return false;
      return true;
    },
    allowManagingFiles() {
      if (this.isUserCustomer) {
        // if the user is customer, then allow manage uploads for workflow of type review or import
        if (this.isReviewReport) {
          return false;
        }
        return this.isWorkflowType("review") || this.isWorkflowType("import");
      }
      if (this.isUserDpo) {
        // if the user is dpo
        // then allow manage uploads for workflow type review with contract type as JCA
        // or if contract is of workflow type Create
        return (
          (this.isWorkflowType("review") && this.isContractTypeJCA && this.isReviewReport) ||
          this.isWorkflowType("create")
        );
      }
      // disallow for other cases
      return false;
    },
    hasFilesPermission() {
      return this.$can("add", "portal_for_dpas_and_jcas_files");
    },
    isWorkflowType() {
      return typeName => {
        return this.contractDetails.workflow_type.includes(typeName);
      };
    },
    isContractTypeJCA() {
      return this.contractDetails.contract_type === "jca";
    },
    isUserCustomer() {
      return this.userRole === "customer";
    },
    isUserDpo() {
      return this.userRole.includes("dpo");
    },
    userRole() {
      return this.userData?.role || "";
    },
    ...mapGetters(["userData"]),
  },
  mounted() {
    window.addEventListener("click", this.closeDropdowns);
  },
  destroyed() {
    window.removeEventListener("click", this.closeDropdowns);
  },
  methods: {
    getFixedUrl(url) {
      const urlParts = url.split("redirect_to=");
      return urlParts.length > 1 ? urlParts[1] : url;
    },
    showFilePopover() {
      document.querySelectorAll(".dg-ppm-details-file__dropdown").forEach(ele => {
        ele.style.visibility = "hidden";
        ele.style.opacity = "0";
      });
      this.$refs.filesDropdown.style.visibility = "visible";
      this.$refs.filesDropdown.style.opacity = "1";
      this.setDropdownPosition();
    },
    closeDropdowns(e) {
      if (this.$el && !this.$el.contains(e.target)) {
        this.hideFilePopover();
      }
    },
    hideFilePopover() {
      this.$refs.filesDropdown.style.visibility = "hidden";
      this.$refs.filesDropdown.style.opacity = "0";
      this.resetDropdownPosition();
    },
    setDropdownPosition() {
      const screenWidth = window.screen.width;
      const fileContainerBox = this.$refs.fileContainer.getBoundingClientRect();
      // if file container's x position is beyond the middle of screen then show dropdown from right
      if (fileContainerBox.x > screenWidth / 2) {
        this.$refs.filesDropdown.style.right = "10px";
      } else {
        // if dropdown's x position is beyond the middle of screen then show dropdown from left
        this.$refs.filesDropdown.style.right = null;
      }
    },
    resetDropdownPosition() {
      this.$refs.filesDropdown.style.right = null;
    },
    showDeleteFileModal(file) {
      this.showConfirmDeleteModal = true;
      this.fileForDeletion = file;
    },
    closeDeleteModal() {
      this.showConfirmDeleteModal = false;
      this.fileForDeletion = null;
    },
    async confirmDeleteFile() {
      const { id } = this.$route.params;
      this.deletingFile = true;
      try {
        const formBody = {
          files: [
            {
              ...this.fileForDeletion,
              _destroy: true,
            },
          ],
        };
        const endpoint = `/api1/v2/customers/${id}/privacy_contracts/${this.contractId}`;
        const response = await this.$http({
          url: endpoint,
          method: "PUT",
          data: formBody,
        });
        this.$dgNotification.show({
          variant: "bold",
          type: "success",
          title: this.$t("ppm.details.file_delete_popup.successMsg"),
        });
        this.$emit("on-files-change", response.data.data.files);
      } catch (error) {
        const { data = [] } = error;
        if (Array.isArray(data) && data.length) {
          this.$dgNotification.show({
            variant: "bold",
            type: "error",
            title: data[0].detail,
          });
        } else {
          this.$dgNotification.show({
            variant: "bold",
            type: "error",
            title: this.$t("ppm.details.manage_uploads.errMsg"),
          });
        }
      }
      this.deletingFile = false;
      this.closeDeleteModal();
    },
    downloadFile(file) {
      const link = document.createElement("a");
      link.href = file.url;
      link.download = file.file_name;
      link.click();
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    fileToData(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    async prepareFilesArray(filesArray) {
      return new Promise(resolve => {
        const filesArrWithBase64 = [];
        [...filesArray].forEach(async file => {
          let contentBuffer = await this.fileToData(file);
          filesArrWithBase64.push({
            file_name: file.name,
            file_type: this.docType,
            file: contentBuffer,
          });
          if (filesArrWithBase64.length === filesArray.length) {
            resolve(filesArrWithBase64);
          }
        });
      });
    },
    async buildFormData(filesArray) {
      const formData = {};
      formData["files"] = await this.prepareFilesArray(filesArray);
      return formData;
    },
    isFileValid(filesArray) {
      const FIVE_MB_IN_BYTES = 5242880;
      let isFileValid = false;
      filesArray.forEach(chosenFile => {
        const { size } = chosenFile;
        // TODO: For now we check for size but will extend this to handle file type as well in the next story
        const hasLargeFileSize = size > FIVE_MB_IN_BYTES; // 5 MB
        if (hasLargeFileSize) {
          this.$dgNotification.show({
            variant: "bold",
            type: "warning",
            title: this.$t("ppm.form.fileErrors.largeFileError"),
          });
        }
        isFileValid = !hasLargeFileSize;
      });
      return isFileValid;
    },
    async uploadNewVersion(e) {
      const files = [...e.target.files];
      if (this.isFileValid(files)) {
        const { id } = this.$route.params;
        this.uploadingFiles = true;
        try {
          const formBody = await this.buildFormData(files);
          const endpoint = `/api1/v2/customers/${id}/privacy_contracts/${this.contractId}`;
          const response = await this.$http({
            url: endpoint,
            method: "PUT",
            data: formBody,
          });
          this.$dgNotification.show({
            variant: "bold",
            type: "success",
            title: this.$t("ppm.details.manage_uploads.successMsg"),
          });
          this.$emit("on-files-change", response.data.data.files);
        } catch (error) {
          const { data = [] } = error;
          if (Array.isArray(data) && data.length) {
            this.$dgNotification.show({
              variant: "bold",
              type: "error",
              title: data[0].detail,
            });
          } else {
            this.$dgNotification.show({
              variant: "bold",
              type: "error",
              title: this.$t("ppm.details.manage_uploads.errMsg"),
            });
          }
        }
        this.uploadingFiles = false;
      }
      e.target.value = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.dg-ppm-details-file {
  position: relative;

  &__dropdown {
    background-color: white;
    border-radius: 4px;
    border: 1px solid #dfe4e9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    opacity: 0;
    position: absolute;
    top: 100%;
    transition: visibility 0s, opacity 0.5s linear;
    width: 465px;
    visibility: hidden;
    z-index: 1;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    }

    &__add-new {
      position: relative;
    }

    &__file-input {
      display: none;
    }

    &__list {
      max-height: 250px;
      overflow-y: scroll;
      list-style: none;
      margin: 0;
      padding: 0;
      color: #0b344b;

      &-item {
        padding: 9px 12px;
        display: flex;
        border-top: 1px solid #dcdcdc;
      }
    }

    &__empty-list {
      border-top: 1px solid #dcdcdc;
      border-bottom: 1px solid #dcdcdc;
      text-align: center;
      padding: 9px 0;
    }

    &__version {
      width: 140px;
    }

    &__owner {
      flex: 1;
      padding-left: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__controls > button {
      background-color: white;
      outline: none;
      border: 0;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__container {
    display: flex;
    background-color: $white;
    align-items: center;
    border: 1px solid #7e93a7;
    margin-right: 10px;
    margin-bottom: 10px;
    width: fit-content;
    cursor: pointer;
    padding: 7px;
    text-decoration: none;
    color: $dark;

    &:hover {
      border-color: $dark;
    }
  }

  &__icon,
  &__down-arrow {
    width: 16px;
  }

  &__down-arrow {
    margin-left: 16px;
  }

  &__details {
    flex: 1;
    margin-left: 12px;
  }

  &__info,
  &__number {
    margin: 0;
  }

  &__info {
    font-size: 12px;
    line-height: 16px;
  }

  &__number {
    color: #cb333b;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    margin-top: 2px;
  }
  .ppm-file-list-enter-active,
  .ppm-file-list-leave-active {
    transition: 700ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
    transition-property: opacity, transform;
  }
  .ppm-file-list-enter {
    opacity: 0;
    transform: translateX(50px) scaleY(0.5);
  }
  .ppm-file-list-leave-to {
    opacity: 0;
    transform: scaleY(0);
    transform-origin: center top;
  }
}
</style>
