<template>
  <div class="dg-ppm-details__tree-container">
    <p class="dg-ppm-details__tree-container__title">{{ $t("ppm.details.subcontractor_tree.title") }}</p>
    <section ref="subcontractorTree" id="dg-ppm-details__subcontractor-tree"></section>
  </div>
</template>

<script>
import * as Sentry from "@sentry/vue";
import { mapGetters } from "vuex";
import mitchTree from "d3-mitch-tree";

import { PPMDetailsMixin } from "../../mixins";

export default {
  mixins: [PPMDetailsMixin],
  data() {
    return {
      treePlugin: null,
      currentNodeItem: null,
    };
  },
  computed: {
    customerId() {
      return this.$route.params.id;
    },
    ...mapGetters(["subContractorTree", "selectedContract", "refreshSubcontractorTree"]),
  },
  watch: {
    refreshSubcontractorTree(shouldRefresh) {
      /**
       * This logic is derived after studying the d3-mitch-tree library
       * There is no documentation for this so will try to explain it through comments
       */
      // we check if we need to refresh the tree
      if (shouldRefresh) {
        // we get the node data in the mitch tree
        const nodeItem = this.treePlugin.getNode(this.currentNodeItem);
        let existingChildren = [];
        if (nodeItem.hasOwnProperty("children")) {
          /**
           * the library uses children property to render child nodes
           * we set it to null so that the library re-renders the node with new data
           */
          nodeItem.children = null;
          existingChildren = nodeItem.data.sub_contracts || [];
        }
        // If there are no existing child subcontracts, we push the newly added one.
        if (existingChildren.length === 0) {
          existingChildren.push(this.selectedContract.processor.sub_contracts.at(-1));
        }
        /**
         * We pass existing children and new child data to re-render newly added subcontractor data
         */
        this.treePlugin._processLoadedDataForNodeFocus(nodeItem, existingChildren);
        // we set refresh flag to false
        this.$store.commit("RESET_REFRESH_SUBCONTRACT_TREE");
      }
    },
  },
  mounted() {
    this.prepareSubcontractorTree();
  },
  methods: {
    prepareSubcontractorTree() {
      this.treePlugin = new mitchTree.boxedTree()
        .setData(this.subContractorTree)
        .setElement(this.$refs.subcontractorTree)
        .setIdAccessor(data => data.id)
        .setChildrenAccessor(data => data.sub_contracts)
        .setBodyDisplayTextAccessor(data => data.partner_name)
        .setTitleDisplayTextAccessor(() => {
          return this.$t("ppm.details.subcontractor_tree.labels.details");
        })
        .getLoadOnDemandSettings()
        .setHasChildrenMethod(function (data) {
          return data.include_children;
        })
        .setLoadChildrenMethod(this.loadSubcontractList)
        .back()
        .on("nodeClick", this.selectContract)
        .initialize();
    },
    async callEndpoint(endpoint) {
      const {
        data: { data: response },
      } = await this.$http.get(endpoint);
      return response;
    },
    async loadSubcontractList({ id: nodeId }, processData) {
      try {
        const subContractList = await this.callEndpoint(
          `/api1/v2/customers/${this.customerId}/privacy_contracts?by_parent=${nodeId}`
        );
        this.$store.commit("UPDATE_SUBCONTRACTOR_TREE", { nodeId, subContractList });
        processData(subContractList);
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    selectContract(event) {
      const {
        nodeDataItem: { data: nodeData },
      } = event;
      if (nodeData.id) {
        // store currently selected node ID, will be used later when we add subcontract
        this.currentNodeItem = nodeData.id;
        this.showContractDetailsPanel(nodeData);
      } else {
        // hide contract details slider
        this.$store.commit("SET_SELECTED_CONTRACT_ID", null);
      }
    },
    async showContractDetailsPanel(nodeData) {
      if (!nodeData.partner_info) {
        // if partner info doesn't exist, fetch it from backend
        await this.fetchContractDetails(nodeData.id);
      }
      this.$store.commit("SET_SELECTED_CONTRACT_ID", nodeData.id);
    },
    async fetchContractDetails(contractId) {
      try {
        const contractData = await this.callEndpoint(
          `/api1/v2/customers/${this.customerId}/privacy_contracts/${contractId}`
        );
        const partnerInfo = contractData.partners.find(partner => partner.role === "processor");
        /**
         * Save partnerInfo in tree data so that when user clicks on a node twice
         * We don't fetch details from backend again
         */
        this.$store.commit("UPDATE_SUBCONTRACTOR_TREE", {
          nodeId: contractId,
          contractData,
          partnerInfo,
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    },
  },
};
</script>

<style lang="scss">
.dg-ppm-details__tree-container {
  margin-top: 16px;

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #7e93a7;
    padding-bottom: 10px;
    border-bottom: 1px solid #cbd4dc;
  }
}

#dg-ppm-details__subcontractor-tree {
  .body-box,
  .title-box {
    rx: 0;
    ry: 0;
    stroke: unset;
  }

  .body-box {
    fill: #cbd4dc;
  }

  .title-box {
    fill: #cb333b;
  }

  .selected .body-box {
    fill: #c7e5f2;
  }
}
</style>
