<template>
  <div class="dg-ppm-subcontractor-details">
    <div class="dg-ppm-subcontractor-details__close">
      <button class="dg-ppm-subcontractor-details__close-btn" @click="closePanel">
        <img class="dg-ppm-subcontractor-details__close-icon" src="../../images/ppm-cross.svg" alt="Close" />
      </button>
    </div>
    <PPMContractRelation />
    <PPMRelationFiles />
    <PPMContractDescendants />
    <div class="dg-ppm-subcontractor-details__add" v-if="showAddSubcontractBtn">
      <dg-button variant-style="secondary" @click="showAddSubcontractModal = true">
        {{ $t("ppm.details.add_subcontract.btn_labels.add") }}
      </dg-button>
    </div>
    <PPMAddSubcontractModal :show="showAddSubcontractModal" @close="showAddSubcontractModal = false" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
  PPMContractRelation,
  PPMRelationFiles,
  PPMContractDescendants,
  PPMAddSubcontractModal,
} from "./PPMSubContractorDetails";

export default {
  components: {
    PPMContractRelation,
    PPMRelationFiles,
    PPMContractDescendants,
    PPMAddSubcontractModal,
  },
  data() {
    return {
      showAddSubcontractModal: false,
    };
  },
  computed: {
    selectedPartner() {
      return this.selectedContract.processor;
    },
    showAddSubcontractBtn() {
      // if current selected partner matches with the ppm details id
      // then check if the contract is a DPA to show button otherwise hide it for other contract types
      if (this.$route.params.ppmId === this.selectedPartner.id) {
        return this.ppmDetails.contract_type === "dpa";
      }
      // if current selected partner is a subcontract, always show the button
      return true;
    },
    ...mapGetters(["ppmDetails", "selectedContract"]),
  },
  methods: {
    closePanel() {
      this.$store.commit("SET_SELECTED_CONTRACT_ID", null);
    },
  },
};
</script>

<style lang="scss">
.dg-ppm-subcontractor-details {
  &__close {
    text-align: right;

    &-btn {
      border: none;
      outline: none;
      background: transparent;
      padding: 0;
    }

    &-icon {
      width: 24px;
      aspect-ratio: 1/1;
    }
  }

  &__add {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
  }
}
</style>
