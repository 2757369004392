<template>
  <div class="ppm-partner-card">
    <h3 :class="['ppm-partner-card__title', { current: partnerType === 'processor' }]">
      {{ partnerDetails.partner_name }}
    </h3>
    <div class="ppm-partner-card__details">
      <template v-if="isPartnerCustomer">
        <p class="ppm-partner-card__info">{{ companyData.address }}</p>
      </template>
      <template v-else>
        <p class="ppm-partner-card__info">{{ streetLabel }}</p>
        <p class="ppm-partner-card__info">{{ cityLabel }}</p>
        <p class="ppm-partner-card__info">{{ fullName }}</p>
        <p class="ppm-partner-card__info">{{ phoneLabel }}</p>
        <p class="ppm-partner-card__info">{{ emailLabel }}</p>
      </template>
    </div>
  </div>
</template>

<script>
import countryList from "../../../store/countries.json";
import { mapGetters } from "vuex";

export default {
  props: {
    partnerType: {
      type: String,
      required: true,
    },
  },
  computed: {
    partnerDetails() {
      return this.selectedContract[this.partnerType];
    },
    isPartnerCustomer() {
      return this.partnerDetails?.is_customer;
    },
    partnerInfo() {
      return this.partnerDetails?.partner_info;
    },
    contactAddress() {
      return this.partnerInfo?.address;
    },
    contactInfo() {
      return this.partnerInfo?.contacts[0];
    },
    countryName() {
      return countryList.find(country => this.contactAddress?.country === country.value)?.name;
    },
    streetLabel() {
      return `${this.contactAddress?.street} ${this.contactAddress?.street_no}`;
    },
    cityLabel() {
      return `${this.contactAddress?.city}, ${this.countryName}`;
    },
    fullName() {
      return `${this.contactInfo.first_name} ${this.contactInfo.last_name}`;
    },
    phoneLabel() {
      return `${this.contactInfo?.country_code} ${this.contactInfo?.phone}`;
    },
    emailLabel() {
      return this.contactInfo?.email;
    },
    ...mapGetters(["selectedContract", "companyData"]),
  },
};
</script>

<style lang="scss">
.ppm-partner-card {
  background-color: #f2f4f6;
  flex: 1;

  &__title,
  &__info {
    padding: 0;
    margin: 0;
  }

  &__title,
  &__details {
    padding: 6px 12px;
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    background-color: #cbd4dc;

    &.current {
      background-color: #c7e5f2;
    }
  }

  &__info {
    font-weight: 600;
    font-size: 14px;
    margin-top: 4px;
  }
}
</style>
